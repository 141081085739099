import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { navigate } from 'gatsby'

const VodicThankYou = ({ location, pageContext: { locale } }) => {
  return (
    <Layout
      heroImage={null}
      isConditions={false}
      title="Hvala na prijavi"
      text="Primili smo tvoju prijavu i kroz nekoliko minuta na tvoju email adresu bi trebao stići email sa linkom na .pdf priručnik."
      locale={locale}
      hideMeeting = {true}
      cta={{
        content: "Natrag na početnu",
        onClick: () => {navigate("/")}
    }}
    >
      <SEO title="Hvala na prijavi" keywords={['Litto']} lang={locale} />
    </Layout>
  )
}

export default VodicThankYou